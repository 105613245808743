import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UserSessionService } from 'app/services/user-session.service';
import { AppModuleConfig } from 'assets/config/app-module.config';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    selfNavigation = [];

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _sessionService: UserSessionService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.loadMenuItems();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    loadMenuItems(): void
    {
        const hasMenuHeader = false;

        const menuItemHome: any = {};
        menuItemHome.id = 'dashboard';
        menuItemHome.title = 'Dashboard';
        menuItemHome.type = 'item';
        menuItemHome.url = '/dashboard';
        menuItemHome.icon = 'dashboard';

        const menuItemEmployees: any = {};
        menuItemEmployees.id = 'employees';
        menuItemEmployees.title = 'Employees';
        menuItemEmployees.type = 'item';
        menuItemEmployees.url = '/employees';
        menuItemEmployees.icon = 'assignment_ind';

        const menuItemCmsPages: any = {};
        menuItemCmsPages.id = 'pages';
        menuItemCmsPages.title = 'Pages';
        menuItemCmsPages.type = 'item';
        menuItemCmsPages.url = '/pages';
        menuItemCmsPages.icon = 'pages';

        const menuItemCategories: any = {};
        menuItemCategories.id = 'categories';
        menuItemCategories.title = 'Categories';
        menuItemCategories.type = 'item';
        menuItemCategories.url = '/categories';
        menuItemCategories.icon = 'category';

        const menuItemPoll: any = {};
        menuItemPoll.id = 'poll';
        menuItemPoll.title = 'Poll';
        menuItemPoll.type = 'item';
        menuItemPoll.url = '/poll';
        menuItemPoll.icon = 'poll';

        const menuItemAuthors: any = {};
        menuItemAuthors.id = 'authors';
        menuItemAuthors.title = 'Authors';
        menuItemAuthors.type = 'item';
        menuItemAuthors.url = '/authors';
        menuItemAuthors.icon = 'school';

        const menuItemEditions: any = {};
        menuItemEditions.id = 'editions';
        menuItemEditions.title = 'Editions';
        menuItemEditions.type = 'item';
        menuItemEditions.url = '/editions';
        menuItemEditions.icon = 'style';

        const menuItemCovers: any = {};
        menuItemCovers.id = 'covers';
        menuItemCovers.title = 'Covers';
        menuItemCovers.type = 'item';
        menuItemCovers.url = '/covers';
        menuItemCovers.icon = 'import_contacts';

        const menuItemGallery: any = {};
        menuItemGallery.id = 'gallery';
        menuItemGallery.title = 'Media Gallery';
        menuItemGallery.type = 'item';
        menuItemGallery.url = '/gallery';
        menuItemGallery.icon = 'collections';

        const menuItemArticles: any = {};
        menuItemArticles.id = 'articles';
        menuItemArticles.title = 'Articles';
        menuItemArticles.type = 'item';
        menuItemArticles.url = '/articles';
        menuItemArticles.icon = 'chrome_reader_mode';

        const menuItemLiveNews: any = {};
        menuItemLiveNews.id = 'liveNews';
        menuItemLiveNews.title = 'Live News';
        menuItemLiveNews.type = 'item';
        menuItemLiveNews.url = '/liveNews';
        menuItemLiveNews.icon = 'chrome_reader_mode';

        const menuItemBreakNews: any = {};
        menuItemBreakNews.id = 'breakNews';
        menuItemBreakNews.title = 'Breaking News';
        menuItemBreakNews.type = 'item';
        menuItemBreakNews.url = '/breakNews';
        menuItemBreakNews.icon = 'chrome_reader_mode';

        const menuItemAccessToken: any = {};
        menuItemAccessToken.id = 'accessToken';
        menuItemAccessToken.title = 'Facebook Access Token';
        menuItemAccessToken.type = 'item';
        menuItemAccessToken.url = '/accessToken';
        menuItemAccessToken.icon = 'facebook';

        const menuItemArticleListOperations: any = {};
        menuItemArticleListOperations.id = 'articleListOperations';
        menuItemArticleListOperations.title = 'Article List Operations';
        menuItemArticleListOperations.type = 'item';
        menuItemArticleListOperations.url = '/articleListOperations';
        menuItemArticleListOperations.icon = 'chrome_reader_mode';

        const menuItemArticleListSectionOperations: any = {};
        menuItemArticleListSectionOperations.id = 'articleListSectionOperations';
        menuItemArticleListSectionOperations.title = 'Article List Section Operations';
        menuItemArticleListSectionOperations.type = 'item';
        menuItemArticleListSectionOperations.url = '/articleListSectionOperations';
        menuItemArticleListSectionOperations.icon = 'list';

        const menuItemHomeAds: any = {};
        menuItemHomeAds.id = 'homeAds';
        menuItemHomeAds.title = 'Home Ads';
        menuItemHomeAds.type = 'item';
        menuItemHomeAds.url = '/ads';
        menuItemHomeAds.icon = 'tv';

        const menuItemArticleViewsReport: any = {};
        menuItemArticleViewsReport.id = 'articleViewsReport';
        menuItemArticleViewsReport.title = 'Article Views Report';
        menuItemArticleViewsReport.type = 'item';
        menuItemArticleViewsReport.url = '/articleViewsReport';
        menuItemArticleViewsReport.icon = 'chrome_reader_mode';

        const menuItemCmsPage: any = {};
        menuItemCmsPage.id = 'pages';
        menuItemCmsPage.title = 'Pages';
        menuItemCmsPage.type = 'item';
        menuItemCmsPage.url = '/pages';
        menuItemCmsPage.icon = 'pages';

        const menuItemHoroscope: any = {};
        menuItemHoroscope.id = 'horoscope';
        menuItemHoroscope.title = 'Horoscope';
        menuItemHoroscope.type = 'item';
        menuItemHoroscope.url = '/horoscope';
        menuItemHoroscope.icon = 'wb_sunny';

        const menuItemAppusers: any = {};
        menuItemAppusers.id = 'appusers';
        menuItemAppusers.title = 'Appusers';
        menuItemAppusers.type = 'item';
        menuItemAppusers.url = '/appusers';
        menuItemAppusers.icon = 'people';

        const menuItemDepartments: any = {};
        menuItemDepartments.id = 'departments';
        menuItemDepartments.title = 'Departments';
        menuItemDepartments.type = 'item';
        menuItemDepartments.url = '/departments';
        menuItemDepartments.icon = 'developer_board';

        const menuItemCountries: any = {};
        menuItemCountries.id = 'countries';
        menuItemCountries.title = 'Countries';
        menuItemCountries.type = 'item';
        menuItemCountries.url = '/countries';
        menuItemCountries.icon = 'map';

        const menuItemStates: any = {};
        menuItemStates.id = 'states';
        menuItemStates.title = 'States';
        menuItemStates.type = 'item';
        menuItemStates.url = '/states';
        menuItemStates.icon = 'location_searching';

        const menuItemCities: any = {};
        menuItemCities.id = 'cities';
        menuItemCities.title = 'Cities';
        menuItemCities.type = 'item';
        menuItemCities.url = '/cities';
        menuItemCities.icon = 'location_on';

        const menuItemRoles: any = {};
        menuItemRoles.id = 'roles';
        menuItemRoles.title = 'Roles';
        menuItemRoles.type = 'item';
        menuItemRoles.url = '/roles';
        menuItemRoles.icon = 'card_membership';

        const menuItemElections: any = {};
        menuItemElections.id = 'elections';
        menuItemElections.title = 'Elections';
        menuItemElections.type = 'item';
        menuItemElections.url = '/elections';
        menuItemElections.icon = 'assistant_photo';

        const menuItemStateElections: any = {};
        menuItemStateElections.id = 'elections';
        menuItemStateElections.title = 'Elections';
        menuItemStateElections.type = 'item';
        menuItemStateElections.url = '/stateelections';
        menuItemStateElections.icon = 'assistant_photo';

        const menuItemElectionStates: any = {};
        menuItemElectionStates.id = 'electionStates';
        menuItemElectionStates.title = 'Election States';
        menuItemElectionStates.type = 'item';
        menuItemElectionStates.url = '/electionStates';
        menuItemElectionStates.icon = 'gamepad';

        const menuItemStateElectionStates: any = {};
        menuItemStateElectionStates.id = 'electionStates';
        menuItemStateElectionStates.title = 'Election States';
        menuItemStateElectionStates.type = 'item';
        menuItemStateElectionStates.url = '/stateelectionStates';
        menuItemStateElectionStates.icon = 'gamepad';

        const menuItemElectionCities: any = {};
        menuItemElectionCities.id = 'electionCities';
        menuItemElectionCities.title = 'Election Cities';
        menuItemElectionCities.type = 'item';
        menuItemElectionCities.url = '/electionCities';
        menuItemElectionCities.icon = 'device_hub';

        // const menuItemStateElectionCities: any = {};
        // menuItemStateElectionCities.id = 'electionCities';
        // menuItemStateElectionCities.title = 'Election Cities';
        // menuItemStateElectionCities.type = 'item';
        // menuItemStateElectionCities.url = '/stateelectionCities';
        // menuItemStateElectionCities.icon = 'device_hub';

        const menuItemParties: any = {};
        menuItemParties.id = 'parties';
        menuItemParties.title = 'Parties';
        menuItemParties.type = 'item';
        menuItemParties.url = '/parties';
        menuItemParties.icon = 'location_city';

        const menuItemStateAlliances: any = {};
        menuItemStateAlliances.id = 'alliances';
        menuItemStateAlliances.title = 'Alliances';
        menuItemStateAlliances.type = 'item';
        menuItemStateAlliances.url = '/alliances';
        menuItemStateAlliances.icon = 'group_work';

        const menuItemStateParties: any = {};
        menuItemStateParties.id = 'parties';
        menuItemStateParties.title = 'Parties';
        menuItemStateParties.type = 'item';
        menuItemStateParties.url = '/stateparties';
        menuItemStateParties.icon = 'location_city';

        const menuItemConstituencyType: any = {};
        menuItemConstituencyType.id = 'constituencyType';
        menuItemConstituencyType.title = 'Election Type';
        menuItemConstituencyType.type = 'item';
        menuItemConstituencyType.url = '/constituencyType';
        menuItemConstituencyType.icon = 'merge_type';

        const menuItemConstituencies: any = {};
        menuItemConstituencies.id = 'Constituencies';
        menuItemConstituencies.title = 'Constituencies';
        menuItemConstituencies.type = 'item';
        menuItemConstituencies.url = '/constituencies';
        menuItemConstituencies.icon = 'map';

        const menuItemContenders: any = {};
        menuItemContenders.id = 'contenders';
        menuItemContenders.title = 'Contenders';
        menuItemContenders.type = 'item';
        menuItemContenders.url = '/contenders';
        menuItemContenders.icon = 'person';

        const stateMenuItemContenders: any = {};
        stateMenuItemContenders.id = 'contenders';
        stateMenuItemContenders.title = 'Contenders';
        stateMenuItemContenders.type = 'item';
        stateMenuItemContenders.url = '/statecontenders';
        stateMenuItemContenders.icon = 'person';

        const stateMenuItemElectionConstituencies: any = {};
        stateMenuItemElectionConstituencies.id = 'electionConstituencies';
        stateMenuItemElectionConstituencies.title = 'Election Constituencies';
        stateMenuItemElectionConstituencies.type = 'item';
        stateMenuItemElectionConstituencies.url = '/electionconstituencies';
        stateMenuItemElectionConstituencies.icon = 'where_to_vote';

        const stateMenuItemVotes: any = {};
        stateMenuItemVotes.id = 'votes';
        stateMenuItemVotes.title = 'Votes';
        stateMenuItemVotes.type = 'item';
        stateMenuItemVotes.url = '/votes';
        stateMenuItemVotes.icon = 'how_to_vote';

        const stateMenuItemResults: any = {};
        stateMenuItemResults.id = 'results';
        stateMenuItemResults.title = 'Results';
        stateMenuItemResults.type = 'item';
        stateMenuItemResults.url = '/results';
        stateMenuItemResults.icon = 'list_alt';

        const menuItemAppBanners: any = {};
        menuItemAppBanners.id = 'appBanners';
        menuItemAppBanners.title = 'App Banners';
        menuItemAppBanners.type = 'item';
        menuItemAppBanners.url = '/appBanners';
        menuItemAppBanners.icon = 'wb_iridescent';

        const menuItemElectionCityResults: any = {};
        menuItemElectionCityResults.id = 'electionCityResults';
        menuItemElectionCityResults.title = 'Election City Results';
        menuItemElectionCityResults.type = 'item';
        menuItemElectionCityResults.url = '/electionCityResults';
        menuItemElectionCityResults.icon = 'assistant_photo';

        /*const menuItemStateElectionCityResults: any = {};
        menuItemStateElectionCityResults.id = 'electionCityResults';
        menuItemStateElectionCityResults.title = 'Election State Results';
        menuItemStateElectionCityResults.type = 'item';
        menuItemStateElectionCityResults.url = '/stateelectionCityResults';
        menuItemStateElectionCityResults.icon = 'assistant_photo';*/

        const menuItemTeamMetrics: any = {};
        menuItemTeamMetrics.id = 'teamMetrics';
        menuItemTeamMetrics.title = 'Team Metrics';
        menuItemTeamMetrics.type = 'item';
        menuItemTeamMetrics.url = '/teamMetrics';
        menuItemTeamMetrics.icon = 'timeline';

        const mainMenuGroupChildren = [];
        const electionMasterMenuGroupChildren = [];
        const stateElectionMasterMenuGroupChildren = [];
        const masterMenuGroupChildren = [];

        mainMenuGroupChildren.push(menuItemHome);

        const teamMetricsRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_TEAM_METRICS_REPORT);
        if (teamMetricsRights && teamMetricsRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemTeamMetrics);
        }

        const appuserRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_APPUSER);
        if (appuserRights && appuserRights.view === 1)
        {
            // mainMenuGroupChildren.push(menuItemAppusers);
        }

        const articleRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ARTICLE);
        if (articleRights && articleRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemArticles);
        }

        const liveNewsRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ARTICLE);
        if (liveNewsRights && liveNewsRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemLiveNews);
        }

        const breakingNewsRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ARTICLE);
        if (breakingNewsRights && breakingNewsRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemBreakNews);
        }
        const accessToken = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ARTICLE);
        if (accessToken && accessToken.view === 1)
        {
            mainMenuGroupChildren.push(menuItemAccessToken);
        }

        const articleListOperationRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ARTICLE);
        if (articleListOperationRights && articleListOperationRights.view === 1)
        {
            // mainMenuGroupChildren.push(menuItemArticleListOperations);
            mainMenuGroupChildren.push(menuItemArticleListSectionOperations);
        }

        const adListOperationRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_HOME_AD);
        if (adListOperationRights && adListOperationRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemHomeAds);
        }

        const articleViewsReportRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_REPORT_VIEWS);
        if (articleViewsReportRights && articleViewsReportRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemArticleViewsReport);
        }

        const horoscopeRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_HOROSCOPE);
        if (horoscopeRights && horoscopeRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemHoroscope);
        }

        const galleryRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_MEDIA_GALLERY);
        if (galleryRights && galleryRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemGallery);
        }

        const coverRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_COVER);
        if (coverRights && coverRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemCovers);
        }

        const editionRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_EDITION);
        if (editionRights && editionRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemEditions);
        }

        const authorRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_AUTHOR);
        if (authorRights && authorRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemAuthors);
        }

        const categoryRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_CATEGORY);
        if (categoryRights && categoryRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemCategories);
        }
        const pollRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_CATEGORY);
        if (pollRights && pollRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemPoll);
        }

        const appBannerRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_APP_BANNER);
        if (appBannerRights && appBannerRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemAppBanners);
        }

        const cmsPageRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_CMS_PAGE);
        if (cmsPageRights && cmsPageRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemCmsPage);
        }

        const employeeRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_EMPLOYEE);
        if (employeeRights && employeeRights.view === 1)
        {
            mainMenuGroupChildren.push(menuItemEmployees);
        }

        const partyRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_PARTY);
        if (partyRights && partyRights.view === 1)
        {
            electionMasterMenuGroupChildren.push(menuItemParties);
        }
        const stateAlliancesRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_PARTY);
        if (stateAlliancesRights && stateAlliancesRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(menuItemStateAlliances);
        }

        const statePartyRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_PARTY);
        if (statePartyRights && statePartyRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(menuItemStateParties);
        }

        const stateConstituencyTypeRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_PARTY);
        if (stateConstituencyTypeRights && stateConstituencyTypeRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(menuItemConstituencyType);
        }

        const stateElectionRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ELECTION);
        if (stateElectionRights && stateElectionRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(menuItemStateElections);
        }

        const StateElectionStateRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ELECTION_STATE);
        if (StateElectionStateRights && StateElectionStateRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(menuItemStateElectionStates);
        }

        if (StateElectionStateRights && StateElectionStateRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(menuItemConstituencies);
        }

        const contenderRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_CONTENDER);
        if (contenderRights && contenderRights.view === 1)
        {
            electionMasterMenuGroupChildren.push(menuItemContenders);
        }
        const stateContenderRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_CONTENDER);
        if (stateContenderRights && stateContenderRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(stateMenuItemContenders);
        }

        if (stateContenderRights && stateContenderRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(stateMenuItemElectionConstituencies);
        }

        /*if (stateContenderRights && stateContenderRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(stateMenuItemVotes);
        }*/

        if (stateContenderRights && stateContenderRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(stateMenuItemResults);
        }

        const electionRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ELECTION);
        if (electionRights && electionRights.view === 1)
        {
            electionMasterMenuGroupChildren.push(menuItemElections);
        }


        const electionCityResultRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ELECTION_CITY_RESULT);
        if (electionCityResultRights && electionCityResultRights.view === 1)
        {
            electionMasterMenuGroupChildren.push(menuItemElectionCityResults);
        }
        /*const stateElectionCityResultRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ELECTION_CITY_RESULT);
        if (stateElectionCityResultRights && stateElectionCityResultRights.view === 1)
        {
            stateElectionMasterMenuGroupChildren.push(menuItemStateElectionCityResults);
        }*/

        const electionStateRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ELECTION_STATE);
        if (electionStateRights && electionStateRights.view === 1)
        {
            electionMasterMenuGroupChildren.push(menuItemElectionStates);
        }

        const electionCityRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ELECTION_CITY);
        if (electionCityRights && electionCityRights.view === 1)
        {
            electionMasterMenuGroupChildren.push(menuItemElectionCities);
        }
        //   const stateElectionCityRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ELECTION_CITY);
        //   if (stateElectionCityRights && stateElectionCityRights.view === 1)
        // {
        //   stateElectionMasterMenuGroupChildren.push(menuItemStateElectionCities);
        // }

        const countryRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_COUNTRY);
        if (countryRights && countryRights.view === 1)
        {
            masterMenuGroupChildren.push(menuItemCountries);
        }

        const stateRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_STATE);
        if (stateRights && stateRights.view === 1)
        {
            masterMenuGroupChildren.push(menuItemStates);
        }

        const cityRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_CITY);
        if (cityRights && cityRights.view === 1)
        {
            masterMenuGroupChildren.push(menuItemCities);
        }

        const departmentRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_DEPARTMENT);
        if (departmentRights && departmentRights.view === 1)
        {
            masterMenuGroupChildren.push(menuItemDepartments);
        }

        const roleRights = this._sessionService.getUserModuleRights(AppModuleConfig.MOD_ROLE);
        if (roleRights && roleRights.view === 1)
        {
            masterMenuGroupChildren.push(menuItemRoles);
        }

        if (mainMenuGroupChildren && mainMenuGroupChildren.length > 0) {
            if (hasMenuHeader) {
                const mainMenuGroup: any = {};
                mainMenuGroup.id = 'menu';
                mainMenuGroup.title = 'Menu';
                mainMenuGroup.type = 'group';
                mainMenuGroup.children = mainMenuGroupChildren;

                this.selfNavigation.push(mainMenuGroup);
            }
            else {
                for (let i = 0 ; i < mainMenuGroupChildren.length ; i++) {
                    this.selfNavigation.push(mainMenuGroupChildren[i]);
                }
            }
        }

        if (electionMasterMenuGroupChildren && electionMasterMenuGroupChildren.length > 0) {
            const electionMastersMenuGroup: any = {};
            electionMastersMenuGroup.id = 'electionMasters';
            electionMastersMenuGroup.title = 'Election Masters';
            electionMastersMenuGroup.type = 'collapsable';
            electionMastersMenuGroup.icon = 'gradient';
            electionMastersMenuGroup.children = electionMasterMenuGroupChildren;

            this.selfNavigation.push(electionMastersMenuGroup);
        }

        if (stateElectionMasterMenuGroupChildren && stateElectionMasterMenuGroupChildren.length > 0) {
            const electionMastersMenuGroup: any = {};
            electionMastersMenuGroup.id = 'StateElectionMasters';
            electionMastersMenuGroup.title = 'State Election Masters';
            electionMastersMenuGroup.type = 'collapsable';
            electionMastersMenuGroup.icon = 'gradient';
            electionMastersMenuGroup.children = stateElectionMasterMenuGroupChildren;

            this.selfNavigation.push(electionMastersMenuGroup);
        }

        if (masterMenuGroupChildren && masterMenuGroupChildren.length > 0) {
            const mastersMenuGroup: any = {};
            mastersMenuGroup.id = 'masters';
            mastersMenuGroup.title = 'Masters';
            mastersMenuGroup.type = 'collapsable';
            mastersMenuGroup.icon = 'group_work';
            mastersMenuGroup.children = masterMenuGroupChildren;

            this.selfNavigation.push(mastersMenuGroup);
        }

    }
}
