import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { url } from 'inspector';
import { UserSessionService } from './user-session.service';
import { AppApiConfig } from 'assets/config/app-api.config';


@Injectable()
export class LiveNewsService
{
    liveNews: any[];
    onLiveNewsChanged: BehaviorSubject<any> = new BehaviorSubject({});
    reqHeaders;

    constructor(
        private _http: HttpClient,
        private _sessionService: UserSessionService
    )
    {
        this.reqHeaders = this._sessionService.getSessionHeaders();
    }

    getLiveNews(): Observable<any>{
        const reqParams = { };
        const reqHeaders = this._sessionService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_LIVE_NEWS_LIST_URL, reqParams, { headers: reqHeaders });
    }

    setLiveNews(formData): Observable<any>{
        const reqParams = formData;
        const reqHeaders = this._sessionService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_SAVE_LIVE_NEWS_URL, reqParams, { headers: reqHeaders });
    }

    getLiveNewsDetail(articleId): Observable<any>{
        const reqParams = {'articleId': articleId};
        const reqHeaders = this._sessionService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_LIVE_NEWS_DETAIL_LIST_URL, reqParams, { headers: reqHeaders });
    }

    deleteLN(lnID): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.delete(AppApiConfig.API_LIVE_NEWS_URL + '/' + lnID, { headers: this.reqHeaders })
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    /*liveNewsAction(lnID, action): Observable<any> {
        const reqParams = {'lnID': lnID, 'action': action};
        const reqHeaders = this._sessionService.getSessionHeaders();
        return this._http.post(AppApiConfig.API_LIVE_NEWS_STATUS_URL, reqParams, { headers: reqHeaders });
    }*/

    liveNewsAction(lnID, action): Promise<any> {
        const reqParams = {'lnID': lnID, 'action': action};
        const reqHeaders = this._sessionService.getSessionHeaders();
        return new Promise((resolve, reject) => {
            this._http.post(AppApiConfig.API_LIVE_NEWS_STATUS_URL, reqParams, { headers: reqHeaders })
                .subscribe(response => {
                    resolve(response);
                });
        });
    }


}
