export class AppModuleConfig
{
  // MODULES
  public static MOD_DEPARTMENT = 'Department';
  public static MOD_EMPLOYEE = 'Employee';
  public static MOD_MODULE = 'Module';
  public static MOD_ROLE = 'Role';
  public static MOD_COUNTRY = 'Country';
  public static MOD_STATE = 'State';
  public static MOD_CITY = 'City';
  public static MOD_CMS_PAGE = 'CMS-Page';
  public static MOD_AUTHOR = 'Author';
  public static MOD_CATEGORY = 'Category';
  public static MOD_EDITION = 'Edition';
  public static MOD_COVER = 'Cover';
  public static MOD_ARTICLE = 'Article';
  public static MOD_APPUSER = 'Appuser';
  public static MOD_HOME_AD = 'Home-Ad';
  public static MOD_MEDIA_GALLERY = 'Media-Gallery';
  public static MOD_REPORT_VIEWS = 'Report-Views';
  public static MOD_REPORT_SELF_ASSESSMENT = 'Report-Self-Assessment';
  public static MOD_HOROSCOPE = 'Horoscope';
  public static MOD_ARTICLE_MULTI_OPERATIONS = 'Article'; // 'Article-Multi-Operations';

  public static MOD_TEAM_METRICS_REPORT = 'TeamMetricsReport'; // 'Article-Multi-Operations';

  public static MOD_PARTY = 'Party';
  public static MOD_CONTENDER = 'Contender';
  public static MOD_ELECTION = 'Election';
  public static MOD_ELECTION_RESULT = 'ElectionResult';
  public static MOD_ELECTION_STATE = 'ElectionState';
  public static MOD_ELECTION_CITY = 'ElectionCity';

  public static MOD_APP_BANNER = 'AppBanner';
  public static MOD_ELECTION_CITY_RESULT = 'ElectionCityResult';

}
