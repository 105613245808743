import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { EmployeeService } from './services/employee.service';
import { AppCommonService } from './services/app-common.service';
import { UserSessionService } from './services/user-session.service';
import { NgxLoadingModule } from 'ngx-loading';
import { MatSearchSelectService } from './services/matSearchSelect.service';
import { CommonRoutingService } from './services/common-routing.service';
import { ReportService } from './services/report.service';
import { ArticleViewsReportService } from './services/articleViewsReport.service';
import { DatePipe } from '@angular/common';
import { AppPdfService } from './services/appPdf.service';
import { AppTeamMetricsReportPdfService } from 'app/services/appTeamMetricsReportPdf.service';
import {BreakingNewsService} from './services/breakingNews.service';
import {AccessTokenService} from './services/accessToken.service';
import {StateContenderModule} from './main/pages/statecontender/contender.module';
import {LiveNewsModule} from './main/pages/liveNews/liveNews.module';
import {LiveNewsService} from './services/liveNews.service';
import {VotesModule} from "./main/pages/votes/votes.module";
// import {ArticleModule} from "./main/pages/liveNews/liveNews.module";


// const appRoutes: Routes = [
//     {
//         path      : '**',
//         redirectTo: 'home'
//     }
// ];

const appRoutes: Routes = [
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/cms/pages/pages.module').then(m => m.PagesModule)
    },
    {

        path        : 'page',
        loadChildren: () => import('./main/pages/cms/page/page.module').then(m => m.PageModule)
    },
    {
        path        : 'roles',
        loadChildren: () => import('./main/pages/roles/roles.module').then(m => m.RolesModule)
    },
    {
        path        : 'employees',
        loadChildren: () => import('./main/pages/employees/employees.module').then(m => m.EmployeesModule)
    },
    {
        path        : 'departments',
        loadChildren: () => import('./main/pages/departments/departments.module').then(m => m.DepartmentsModule)
    },
    {
        path        : 'countries',
        loadChildren: () => import('./main/pages/countries/countries.module').then(m => m.CountriesModule)
    },
    {
        path        : 'states',
        loadChildren: () => import('./main/pages/states/states.module').then(m => m.StatesModule)
    },
    {
        path        : 'cities',
        loadChildren: () => import('./main/pages/cities/cities.module').then(m => m.CitiesModule)
    },
    {
        path        : 'modules',
        loadChildren: () => import('./main/pages/modules/modules.module').then(m => m.ModulesModule)
    },
    {
        path        : 'categories',
        loadChildren: () => import('./main/pages/categories/categories.module').then(m => m.CategoriesModule)
    },
    {
        path        : 'poll',
        loadChildren: () => import('./main/pages/poll/categories.module').then(m => m.CategoriesModule)
    },
    {
        path        : 'authors',
        loadChildren: () => import('./main/pages/authors/authors.module').then(m => m.AuthorsModule)
    },
    {
        path        : 'article',
        loadChildren: () => import('./main/pages/article/article.module').then(m => m.ArticleModule)
    },
    {
        path        : 'liveNews',
        loadChildren: () => import('./main/pages/liveNews/liveNews.module').then(m => m.LiveNewsModule)
    },
    {
        path        : 'liveNewsForm',
        loadChildren: () => import('./main/pages/liveNewsForm/liveNewsForm.module').then(m => m.LiveNewsFormModule)
    },
    {
        path        : 'breakNews',
        loadChildren: () => import('./breakNews/breakNews.module').then(m => m.BreakNewsModule)
    },
    {
        path        : 'accessToken',
        loadChildren: () => import('./accessToken/accessToken.module').then(m => m.AccessTokenModule)
    },
    {
        path        : 'articleDetail',
        loadChildren: () => import('./main/pages/articleDetails/articleDetail.module').then(m => m.ArticleDetailModule)
    },

    // {
    //     path        : 'articles',
    //     loadChildren: './main/pages/articles/articles.module#ArticlesModule'
    // },
    {
        path        :  'articles', // 'articleListOperations',
        loadChildren: () => import('./main/pages/articleListOperations/articleListOperations.module').then(m => m.ArticleListOperationsModule)
    },
    {
        path        : 'cover',
        loadChildren: () => import('./main/pages/cover/cover.module').then(m => m.CoverModule)
    },
    {
        path        : 'covers',
        loadChildren: () => import('./main/pages/covers/covers.module').then(m => m.CoversModule)
    },
    {
        path        : 'edition',
        loadChildren: () => import('./main/pages/edition/edition.module').then(m => m.EditionModule)
    },
    {
        path        : 'editions',
        loadChildren: () => import('./main/pages/editions/editions.module').then(m => m.EditionsModule)
    },
    {
        path        : 'gallery',
        loadChildren: () => import('./main/pages/galleries/gallery.module').then(m => m.GalleryModule)
    },
    {
        path        : 'login',
        loadChildren: () => import('./main/pages/authentication/login/login.module').then(m => m.LoginModule)
    },

    {
        path        : 'forgot-password',
        loadChildren: () => import('./main/pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
        path        : 'ad',
        loadChildren: () => import('./main/pages/ad/ad.module').then(m => m.AdModule)
    },
    {
        path        : 'ads',
        loadChildren: () => import('./main/pages/ads/ads.module').then(m => m.AdsModule)
    },
    {
        path        : 'horoscope',
        loadChildren: () => import('./main/pages/horoscope/horoscope.module').then(m => m.HoroscopeModule)
    },
    {
        path        : 'horoscopes',
        loadChildren: () => import('./main/pages/horoscopes/horoscopes.module').then(m => m.HoroscopesModule)
    },
    {
        path        : 'articleViewsReport',
        loadChildren: () => import('./main/pages/articleViewsReport/articleViewsReport.module').then(m => m.ArticleViewsReportModule)
    },
    {
        path        : 'charts',
        loadChildren: () => import('./main/pages/charts/chart.module').then(m => m.ChartModule)
    },
    {
        path        : 'articleListSectionOperations',
        loadChildren: () => import('./main/pages/articleListSectionOperations/articleListSectionOperations.module').then(m => m.ArticleListSectionOperationsModule)
    },
    {
        path        : 'profile',
        loadChildren: () => import('./main/pages/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path        : 'party',
        loadChildren: () => import('./main/pages/party/party.module').then(m => m.PartyModule)
    },
    {
        path        : 'parties',
        loadChildren: () => import('./main/pages/parties/parties.module').then(m => m.PartiesModule)
    },
    {
        path        : 'stateparty',
        loadChildren: () => import('./main/pages/stateparty/party.module').then(m => m.StatePartyModule)
    },
    {
        path        : 'addalliance',
        loadChildren: () => import('./main/pages/alliances/allianceForm/alliance.module').then(m => m.AllianceModule)
    },
    {
        path        : 'alliances',
        loadChildren: () => import('./main/pages/alliances/alliances.module').then(m => m.AlliancesModule)
    },

    /*{
        path        : 'addconstituencyType',
        loadChildren: () => import('./main/pages/constituencyType/constituencyTypeForm/constituencyType.module').then(m => m.ConstituencyTypeModule)
    },*/
    {
        path        : 'constituencyType',
        loadChildren: () => import('./main/pages/constituencyType/constituencyType.module').then(m => m.ConstituencyTypeModule)
    },
    {
        path        : 'constituencies',
        loadChildren: () => import('./main/pages/constituencies/constituencies.module').then(m => m.ConstituenciesModule)
    },
    {
        path        : 'stateparties',
        loadChildren: () => import('./main/pages/stateparties/parties.module').then(m => m.StatePartiesModule)
    },
    {
        path        : 'contender',
        loadChildren: () => import('./main/pages/contender/contender.module').then(m => m.ContenderModule)
    },
    {
        path        : 'contenders',
        loadChildren: () => import('./main/pages/contenders/contenders.module').then(m => m.ContendersModule)
    },
    {
        path        : 'statecontender',
        loadChildren: () => import('./main/pages/statecontender/contender.module').then(m => m.StateContenderModule)
    },
    {
        path        : 'statecontenders',
        loadChildren: () => import('./main/pages/statecontenders/contenders.module').then(m => m.StateContendersModule)
    },
    {
        path        : 'addelectionconstituencies',
        loadChildren: () => import('./main/pages/electionConstituenciesForm/electionConstituencies.module').then(m => m.ElectionConstituenciesModule)
    },
    {
        path        : 'electionconstituencies',
        loadChildren: () => import('./main/pages/electionConstituencies/electionConstituencies.module').then(m => m.ElectionConstituenciesModule)
    },
    {
        path        : 'electionStates',
        loadChildren: () => import('./main/pages/electionStates/electionStates.module').then(m => m.ElectionStatesModule)
    },
    {
        path        : 'stateelectionStates',
        loadChildren: () => import('./main/pages/stateelectionStates/electionStates.module').then(m => m.StateElectionStatesModule)
    },
    {
        path        : 'electionCities',
        loadChildren: () => import('./main/pages/electionCities/electionCities.module').then(m => m.ElectionCitiesModule)
    },
    {
        path        : 'stateelectionCities',
        loadChildren: () => import('./main/pages/stateelectionCities/electionCities.module').then(m => m.StateElectionCitiesModule)
    },
    {
        path        : 'elections',
        loadChildren: () => import('./main/pages/elections/elections.module').then(m => m.ElectionsModule)
    },
    {
        path        : 'stateelections',
        loadChildren: () => import('./main/pages/stateelections/elections.module').then(m => m.StateElectionsModule)
    },
    {
        path        : 'electionResult',
        loadChildren: () => import('./main/pages/electionResult/electionResult.module').then(m => m.ElectionResultModule)
    },
    {
        path        : 'stateelectionResult',
        loadChildren: () => import('./main/pages/stateelectionResult/electionResult.module').then(m => m.StateElectionResultModule)
    },
    {
        path        : 'votes',
        loadChildren: () => import('./main/pages/votes/votes.module').then(m => m.VotesModule)
    },
    {
        path        : 'results',
        loadChildren: () => import('./main/pages/results/results.module').then(m => m.ResultsModule)
    },
    {
        path        : 'appBanner',
        loadChildren: () => import('./main/pages/appBanner/appBanner.module').then(m => m.AppBannerModule)
    },
    {
        path        : 'appBanners',
        loadChildren: () => import('./main/pages/appBanners/appBanners.module').then(m => m.AppBannersModule)
    },
    {
        path        : 'electionCityResults',
        loadChildren: () => import('./main/pages/electionCityResults/electionCityResults.module').then(m => m.ElectionCityResultsModule)
    },
    {
        path        : 'stateelectionCityResults',
        loadChildren: () => import('./main/pages/stateelectionCityResults/electionCityResults.module').then(m => m.StateElectionCityResultsModule)
    },
    {
        path        : 'summaryDashboard',
        loadChildren: () => import('./main/pages/summaryDashboard/summaryDashboard.module').then(m => m.SummaryDashboardModule)
    },
    {
        path        : 'teamMetrics',
        loadChildren: () => import('./main/pages/teamMetrics/teamMetrics.module').then(m => m.TeamMetricsModule)
    },
    {
        path        : 'dashboard',
        loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule)
    },
    {
        path        : '**',
        redirectTo  : 'dashboard'
        // loadChildren: './main/home/home.module#HomeModule'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),// { relativeLinkResolution: 'legacy' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        // Custom
        MatSnackBarModule,

        NgxLoadingModule,

        MatTableModule
    ],
    providers: [
        DatePipe,
        EmployeeService,
        UserSessionService,
        AppCommonService,
        MatSearchSelectService,
        AppTeamMetricsReportPdfService,
        AppPdfService,
        ReportService,
        ArticleViewsReportService,
        CommonRoutingService,
        BreakingNewsService,
        LiveNewsService,
        AccessTokenService
    ],
    bootstrap   : [
        AppComponent
    ],
    exports: [
        NgxLoadingModule
    ]
})
export class AppModule
{
}
