
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'assets/config/app.config';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Injectable()
export class AppCommonService
{
    constructor(
        private http: HttpClient,
        public _snackBar: MatSnackBar
    ) {

    }

    getCompanyName(): string {
        const name = AppConfig.COMPANY_NAME;
        return name;
    }

    timestampToDispDate(timestamp): string {
        const date = new Date(timestamp * 1000);

        const dateVal = ('0' + date.getDate()).slice(-2);
        const monthVal = ('0' + (date.getMonth() + 1)).slice(-2);
        const yearVal = date.getFullYear();
        const separator = '-';

        const datevalues =  yearVal + separator + monthVal + separator + dateVal;
        return datevalues;
    }

    timestampToDispDateTimeObj(timestamp): Date {
        const date = new Date(timestamp * 1000);
        return date;
    }

    timestampToDispViewDate(timestamp): string {
        const date = new Date(timestamp * 1000);

        const dateVal = ('0' + date.getDate()).slice(-2);
        const monthVal = ('0' + (date.getMonth() + 1)).slice(-2);
        const yearVal = date.getFullYear();
        const separator = '-';

        const datevalues =  dateVal + separator + monthVal + separator + yearVal;
        return datevalues;
    }

    dateToTimestamp(dateStr): number {
        const dobTs = Math.round((new Date(dateStr).getTime()) / 1000);
        return dobTs;
    }

    
    checkIfStringIsValidForGenericTextRequiredField(consStr) {
        var isValid = false;
        if(consStr !== undefined && consStr !== null && consStr !== '')
        {
            var sanitizedString = consStr.toString().trim();
            sanitizedString = sanitizedString.replace(/ +(?= )/g,'');
            if(sanitizedString !== '')
            {
                isValid = true;
            }
        }
        return isValid;
    }

    checkIfStringIsValidAplhaNumeric(consStr) {
        var regExp = /^([a-zA-Z0-9]+)$/;
        return regExp.test(consStr);
    }

    checkIfStringIsValidHexColorCode(consStr) {
        var regExp = /^#([0-9a-f]{3}){1,2}$/i;
        return regExp.test(consStr);
    }

    checkIfStringIsOnlyInteger(consStr) {
        var regExp = /^-?[0-9]*$/;
        return regExp.test(consStr);
    }

    checkIfStringIsValidURL(consStr) {
        var regExp = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        return regExp.test(consStr);
    }

    checkIfStringIsValidVideoLinkURL(consStr) {
        var regExp = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        return regExp.test(consStr);
    }

    checkIfStringIsValidiframeURL(consStr) {
        var regExp = /^(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/;
        return regExp.test(consStr);
    }

    checkIfStringIsValidAppAlias(consStr) {
        var regExp = /^([a-zA-Z]+)$/;
        return regExp.test(consStr);
    }

    checkIfStringIsValidInteger(consStr) {
        var regExp = /^-?[0-9][^\.]*$/;
        return regExp.test(consStr);
    }

    

    showResponseSnackbar(res: any): void {
        if (res) {
            if (res.message !== undefined && res.message !== '') {
                if (res.status > 0) {
                    this.showSuccessMessageSnackbar(res.message);
                }
                else {
                    this.showErrorMessageSnackbar(res.message);
                }
            }
        }
    }

    showErrorMessageSnackbar(message: string): void {
        this._snackBar.open(message, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration        : 3000,
            panelClass      : [ 'error-snackbar' ]
        });
    }

    showSuccessMessageSnackbar(message: string): void {
        this._snackBar.open(message, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration        : 3000,
            panelClass      : [ 'success-snackbar' ]
        });
    }

    showWarningMessageSnackbar(message: string): void {
        this._snackBar.open(message, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration        : 3000,
            panelClass      : [ 'warning-snackbar' ]
        });
    }

    showInfoMessageSnackbar(message: string): void {
        this._snackBar.open(message, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration        : 3000,
            panelClass      : [ 'info-snackbar' ]
        });
    }
    getCurrentDateTimeString(): string {
        const currDate = moment();

        const formattedValue = currDate.format('DD-MM-YYYY hh:mm a Z');
        return formattedValue;
    }

}
