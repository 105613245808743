import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS operator for mapping the observable

import 'rxjs/add/operator/map';
import { AppConfig } from 'assets/config/app.config';
import { UserRightsModel } from 'app/models/user-rights.model';
import { AutoLogoutService } from './autoLogout.service';

@Injectable()
export class UserSessionService {

    constructor(
        private http: HttpClient
    ) {
        const autoLogoutService = new AutoLogoutService(this);
    }

    setUserSession(empId: string, empName: string, empKey: string, empRights: UserRightsModel[], empEmail: string): void {
        const usrEmpNoKey = AppConfig.SESS_USER_EMPLOYEE_NO;
        const usrNameKey = AppConfig.SESS_USER_NAME;
        const usrIdKey = AppConfig.SESS_USER_KEY;
        const usrEmailKey = AppConfig.SESS_USER_EMAIL;
        const usrRightsKey = AppConfig.SESS_USER_RIGHTS;
        const myObj = { [usrEmpNoKey]: empId, [usrNameKey]: empName, [usrIdKey]: empKey, [usrEmailKey]: empEmail, [usrRightsKey]: empRights};

        localStorage.setItem( AppConfig.SESSION_USER_INFO, JSON.stringify(myObj) );
        localStorage.setItem( AppConfig.SESSSION_IS_USER_LOGGED_IN, '1');
        return;
    }


    removeUserSession(): void {
        localStorage.removeItem( AppConfig.SESSION_USER_INFO );
        localStorage.removeItem( AppConfig.SESSSION_IS_USER_LOGGED_IN );
    }

    getUserLoggedInStatus(): boolean {
        let isLoggedIn = false;
        const isUsrLoggedIn = localStorage.getItem( AppConfig.SESSSION_IS_USER_LOGGED_IN );
        if (isUsrLoggedIn != null && isUsrLoggedIn === '1')
        {
            isLoggedIn = true;
        }
        return isLoggedIn;
    }

    getSessionUserEmployeeNo(): string {
        let empNo = '';
        const userInfoObj = this.getSessionUserInfoObject();
        if (userInfoObj != null)
        {
            const usrEmpNoKey = AppConfig.SESS_USER_EMPLOYEE_NO;
            empNo = userInfoObj[usrEmpNoKey];
        }
        return empNo;
    }

    getSessionUserId(): string {
        let userId = '';
        const userInfoObj = this.getSessionUserInfoObject();
        if (userInfoObj != null)
        {
            const usrIdKey = AppConfig.SESS_USER_KEY;
            userId = userInfoObj[usrIdKey];
        }
        return userId;
    }

    getSessionUserName(): string {
        let userFullname = '';
        const userInfoObj = this.getSessionUserInfoObject();
        if (userInfoObj != null)
        {
            const usrNameKey = AppConfig.SESS_USER_NAME;
            userFullname = userInfoObj[usrNameKey];
        }
        return userFullname;
    }

    getSessionUserEmail(): string {
        let userEmail = '';
        const userInfoObj = this.getSessionUserInfoObject();
        if (userInfoObj != null)
        {
            const usrNameEmail = AppConfig.SESS_USER_EMAIL;
            userEmail = userInfoObj[usrNameEmail];
        }
        return userEmail;
    }

    getSessionUserInfoObject(): JSON {
        let userInfoObj = null;
        const isLoggedIn = this.getUserLoggedInStatus();
        if (isLoggedIn)
        {
            const userInfo = localStorage.getItem( AppConfig.SESSION_USER_INFO );
            userInfoObj = JSON.parse(userInfo);
        }
        return userInfoObj;
    }

    getUserModuleRights(moduleName: string): UserRightsModel {
        let moduleRights = null;
        const userInfoObj = this.getSessionUserInfoObject();
        if (userInfoObj != null)
        {
            const usrRightsKey = AppConfig.SESS_USER_RIGHTS;
            const empRights = userInfoObj[usrRightsKey];

            let keepGoing = true;
            for (let i = 0, len = empRights.length; i < len; i++)
            {
                if (keepGoing)
                {
                    const modName = empRights[i].moduleName;
                    if (modName === moduleName)
                    {
                        moduleRights = empRights[i];
                        keepGoing = false;
                    }
                }
            }
        }
        return moduleRights;
    }


    getApiKeyForHeader(): string {
        return 'API-KEY-AM-PANEL';
    }

    getRequestFromForHeader(): string {
        return 'pnl';
    }

    getSessionHeaders(): HttpHeaders {
        const headers = new HttpHeaders({ 'ekey' : this.getSessionUserId(), 'apikey' : this.getApiKeyForHeader(), 'reqfrom' : this.getRequestFromForHeader() });
        return headers;
    }

    performUserLogout(): void {
        this.removeUserSession();
        return;
    }
}
