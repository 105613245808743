export class AppConfig {
    // Session
    public static SESSION_USER_INFO = "ahmMrrAdm";
    public static SESSSION_IS_USER_LOGGED_IN = "admLoggedIn";
    public static SESS_USER_EMPLOYEE_NO = "admEmpNo";
    public static SESS_USER_NAME = "admName";
    public static SESS_USER_KEY = "admId";
    public static SESS_USER_EMAIL = "admEmail";
    public static SESS_USER_RIGHTS = "admRights";

    public static COMPANY_NAME = "Ahmedabad Mirror";
    public static SYSTEM_NAME = "AM";

    public static VENDOR_NAME = "EterPride";
    public static VENDOR_WEBSITE_URL = "eterpride.com";
    public static DEFAULT_PREVIEW_IMAGE = "assets/images/defaults/default-image.png";
    public static DEFAULT_PREVIEW_VIDEO = "assets/images/defaults/default-video.png";
    public static DEFAULT_PREVIEW_AUDIO = "assets/images/defaults/default-audio.png";

    // Datatables
    public static DATATABLE_DEFAULT_PAGE_LENGTH = 10;
    public static DATATABLE_DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
    public static DATATABLE_DEFAULT_PAGING_TYPE = "full_numbers";

    public static VALIDATION_ACTIVITY_REMINDER_MAX_DAYS =
        45 * 24 * 60 * 60 * 1000;

    // Strings
    public static STRING_APP_NAME = "Ahmedabad Mirror";

    public static DISPLAY_DATE = "d-m-Y";
    public static DATE_DB = "x";

    public static ACCEPTABLE_PDF_UPLOAD_EXTENSIONS = ".pdf";
    public static ACCEPTABLE_IMAGE_UPLOAD_EXTENSIONS = ".png,.jpg,.jpeg";
    public static ACCEPTABLE_IMAGE_UPLOAD_EXTENSIONS_STR = "PNG, JPG, JPEG";

    public static ACCEPTABLE_AUDIO_UPLOAD_EXTENSIONS = ".mp3,.mp4,.aif";
    public static ACCEPTABLE_AUDIO_UPLOAD_EXTENSIONS_STR = "MP3, MP4, AIF";

    public static ACCEPTABLE_VIDEO_UPLOAD_EXTENSIONS = ".flv,.mp4,.3gp";
    public static ACCEPTABLE_VIDEO_UPLOAD_EXTENSIONS_STR = "FLV, MP4, 3GP";

    private static tempCkEditorRemBtnStr =
        "Source,Save,Templates,Preview,NewPage,Print,Cut,Undo,Redo,Copy,Paste," +
        "PasteText,PasteFromWord,Find,Replace,SelectAll,Form,Checkbox,Radio,Textarea," +
        "TextField,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,Language,Anchor," +
        "Unlink,Link,Flash,SpecialChar,PageBreak,Iframe,Styles,Font,Format,Maximize,ShowBlocks,About";

    public static CKEDITOR_CONFIG_MINIMAL = {
        toolbar: "simple",
        toolbarGroups: [
            { name: "document", groups: ["mode", "document", "doctools"] },
            { name: "clipboard", groups: ["clipboard", "undo"] },
            {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"]
            },
            { name: "forms", groups: ["forms"] },
            "/",
            { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
            {
                name: "paragraph",
                groups: [
                    "list",
                    "indent",
                    "blocks",
                    "align",
                    "bidi",
                    "paragraph"
                ]
            },
            { name: "links", groups: ["links"] },
            { name: "insert", groups: ["insert"] },
            '/',
            { name: 'styles', groups: ['styles'] },
            { name: 'colors', groups: ['colors'] },
            { name: 'tools', groups: ['tools'] },
            { name: 'others', groups: ['others'] },
            { name: 'about', groups: ['about'] }
        ],
        removeButtons: AppConfig.tempCkEditorRemBtnStr
    };

    public static CKEDITOR_CONFIG_BASIC = {
        toolbar: [
            {
                name: 'paragraph',
                items: ['NumberedList', 'BulletedList', '-', 'Blockquote']
            }
        ],
        removePlugins: 'elementspath'
    };

    public static CKEDITOR_CONFIG_ARTICLE = {
        toolbar: 'minimalist',
        toolbarGroups: [
            { name: 'document', groups: ['mode', 'document', 'doctools', ''] },
            { name: 'clipboard', groups: ['clipboard', 'undo'] },
            {
                name: 'editing',
                groups: ['find', 'selection', 'spellchecker', 'editing']
            },
            { name: 'forms', groups: ['forms'] },
            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
            {
                name: 'paragraph',
                groups: [
                    'list',
                    'indent',
                    'blocks',
                    'align',
                    'bidi',
                    'paragraph'
                ]
            },
            { name: 'links', groups: ['links'] },
            { name: 'insert', groups: ['insert'] },
            { name: 'styles', groups: ['styles'] },
            { name: 'colors', groups: ['colors'] },
            { name: 'tools', groups: ['tools'] },
            { name: 'others', groups: ['others'] },
            { name: 'about', groups: ['about'] }
            // { name: 'embed', groups:['oembed'] }
        ],

        // tslint:disable-next-line:max-line-length
        removeButtons: 'Save,Print,Preview,NewPage,Templates,Paste,PasteFromWord,Find,SelectAll,Scayt,Form,Radio,Checkbox,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Flash,Table,Smiley,SpecialChar,PageBreak,Styles,Format,Font,FontSize,TextColor,BGColor,About,ShowBlocks,HorizontalRule',
        // keystrokes :[
        //     ['CTRL' + 86 /*V*/, false]
        //   ],
        //  extraPlugins : 'oembed,widget',
        //   addPlugins:'oembed',
        removePlugins: 'elementspath',
        resize_enabled: false,
        forcePasteAsPlainText: true,
        extraPlugins: 'oembed',

        // extraAllowedContent : 'iframe[*]'

        extraAllowedContent: true
    };

    public static AUCTION_IMAGE_MAX_FILE_SIZE_MB = 5;

    public static MY_FORMATS = {
        parse: {
          dateInput: 'DD-MM-YYYY',
        },
        display: {
          dateInput: 'DD-MM-YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'DD-MM-YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      };
}
