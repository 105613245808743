import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import SelectListItem from 'app/models/selectListItem.model';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';

@Injectable()
export class MatSearchSelectService
{
    allItems: SelectListItem[] = [];
    filteredItems: ReplaySubject<SelectListItem[]> = new ReplaySubject<SelectListItem[]>(1);
    selCtrl: FormControl;
    selFilterCtrl: FormControl;
    matSelect: MatSelect;
    _onDestroy: Subject<void>;
    isRequiredInput = false;

    initSelectControl(selectControl: FormControl, selectFilterControl: FormControl, selectView: MatSelect, onDestroySubject: Subject<void>): void {
        this.selCtrl = selectControl;
        this.selFilterCtrl = selectFilterControl;
        this.matSelect = selectView;
        this._onDestroy = onDestroySubject;

        // load the initial bank list
        this.filteredItems.next(this.allItems.slice());

        this.setFilterInitialValue();

        return;
    }

    setIsRequiredInput(isRequired: boolean): void {
        this.isRequiredInput = isRequired;
    }

    setInitialList(itemList: SelectListItem[]): ReplaySubject<SelectListItem[]> {
        this.allItems = itemList;
        this.filterList();
        return this.filteredItems;
    }

    setFilterInitialValue(): void {
        this.filteredItems
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
            // setting the compareWith property to a comparison function
            // triggers initializing the selection according to the initial value of
            // the form control (i.e. _initializeSelection())
            // this needs to be done after the filteredBanks are loaded initially
            // and after the mat-option elements are available
            if (this.matSelect) {
                this.matSelect.compareWith = (a: SelectListItem, b: SelectListItem) => a && b && a.id === b.id;
            }
        });
    }

    onDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    filterList(): ReplaySubject<SelectListItem[]> {
        if (!this.allItems) {
          return;
        }
        // get the search keyword
        let search = this.selFilterCtrl.value;

        if (!search) {
          this.filteredItems.next(this.allItems.slice());
        } else {
          search = search.toLowerCase();

          this.filteredItems.next(
            this.allItems.filter(obj => obj.text.toLowerCase().indexOf(search) > -1)
          );
        }

        return this.filteredItems;
    }

    setInitialSelection(id: string, text: string): void {
        this.setInitialSelectionObj({
            id: id,
            text: text
        });
    }

    setInitialSelectionObj(selectionObj: SelectListItem): void {
        this.selCtrl.setValue(selectionObj);
        this.selCtrl.markAsTouched();
    }

    setInitialSelectionArr(selectionArr: SelectListItem[]): void {
        this.selCtrl.setValue(selectionArr);
        this.selCtrl.markAsTouched();
    }

    getSelectedId(): string {
        const selItem = this.selCtrl.value;
        let selItemVal = '';
        if (selItem) {
            selItemVal = selItem.id;
        }
        return selItemVal;
    }


    getSelectedIdArr(): string[] {
        const selItemArr = this.selCtrl ? this.selCtrl.value : null;
        const selItemValArr: string[] = [];
        if (selItemArr) {
            selItemArr.forEach(selItem => {
                selItemValArr.push(selItem.id);
            });
        }
        return selItemValArr;
    }
    
    getSelectedTextArr(): string[] {
        const selItemArr = this.selCtrl ? this.selCtrl.value : null;
        const selItemValArr: string[] = [];
        if (selItemArr) {
            selItemArr.forEach(selItem => {
                selItemValArr.push(selItem.text);
            });
        }
        return selItemValArr;
    }
    
    
    resetSelection(): void {
        if(this.selCtrl)
        {
            this.selCtrl.setValue('');
            this.selCtrl.markAsUntouched();
        }
    }


    showRequiredError(): boolean {
        let hasError = false;
        if (this.isRequiredInput === true && this.selCtrl) {
            if (this.selCtrl.touched && this.getSelectedId() === '') {
                hasError = true;
            }
        }
        return hasError;
    }

    hasRequiredError(): boolean {
        let hasError = false;
        if (this.isRequiredInput === true && this.selCtrl) {
            if (this.getSelectedId() === '') {
                hasError = true;
            }
        }
        return hasError;
    }

}
